import { type HTMLStyledProps, styled } from 'styled-system/jsx';

export type LinkProps = HTMLStyledProps<'a'> & {
  href: string;
  onClick?: () => void | Promise<void>;
};

const Link = (props: LinkProps) => {
  const { href, onClick, children, ...linkProps } = props;

  return (
    <styled.a
      borderBottomWidth={1}
      borderColor="inherit"
      color="inherit"
      href={href}
      textDecoration="none"
      transition="border-color 68ms linear"
      _active={{ borderColor: 'transparent' }}
      _hover={{ borderColor: 'transparent' }}
      {...(onClick
        ? {
            onClick: async (e) => {
              e.preventDefault();
              await onClick();
            },
          }
        : {})}
      {...linkProps}
    >
      {children}
    </styled.a>
  );
};

export default Link;
