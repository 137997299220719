import { Flex } from 'styled-system/jsx';
import { LighteningIcon } from '@ichingio/icons';
import { IconButton } from '@ichingio/ui';
import { useDivinationFlow } from '~/modals/DivinationFlow';

const ThunderDot = () => {
  const { startNewDivination } = useDivinationFlow();

  return (
    <Flex
      backdropFilter="blur(0.25rem)"
      bg="neutral.800/98"
      borderRadius="9999px"
      boxShadow="xl"
      height="3.65rem"
      width="3.65rem"
    >
      <IconButton
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        onPress={() => startNewDivination()}
        width="100%"
      >
        <LighteningIcon color="white" size="lg" />
      </IconButton>
    </Flex>
  );
};

export default ThunderDot;
