import { type PropsWithChildren } from 'react';
import { Box } from 'styled-system/jsx';

const RowContainer = (props: PropsWithChildren & { count: number }) => {
  const { children, count } = props;

  return (
    <Box
      display="grid"
      gap={3.5}
      {...(count === 6 ? { gridTemplateColumns: 6 } : {})}
      {...(count === 5 ? { gridTemplateColumns: 5 } : {})}
      {...(count === 4 ? { gridTemplateColumns: 4 } : {})}
      {...(count === 3 ? { gridTemplateColumns: 3 } : {})}
      {...(count === 2 ? { gridTemplateColumns: 2 } : {})}
    >
      {children}
    </Box>
  );
};

export default RowContainer;
