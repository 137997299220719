import { Box, type BoxProps, Flex, type FlexProps } from 'styled-system/jsx';
import Text, { type TextProps } from '../Text';
import Touchable, { type TouchableProps } from '../Touchable';

export const Content = (
  props: BoxProps &
    Pick<TouchableProps, 'onPress' | 'onPressStart' | 'onPressEnd'>,
) => {
  const { children, onPress, onPressStart, onPressEnd, ...boxProps } = props;

  return (
    <Touchable
      onPress={onPress}
      onPressStart={onPressStart}
      onPressEnd={onPressEnd}
      width="100%"
      height="100%"
    >
      <Box
        aspectRatio="1 / 1"
        bg="#ececec"
        borderRadius={10}
        cursor="pointer"
        overflow="hidden"
        position="relative"
        width="100%"
        {...boxProps}
      >
        {children}
      </Box>
    </Touchable>
  );
};

export const Description = (props: TextProps) => {
  const { children, ...textProps } = props;

  return (
    <Text color="text" fontSize={['xs', 'sm']} ml={0.5} {...textProps}>
      {children}
    </Text>
  );
};

export const Root = (props: FlexProps) => {
  const { children, ...flexProps } = props;

  return (
    <Flex direction="column" width="100%" {...flexProps}>
      {children}
    </Flex>
  );
};
