import { Route, Switch } from 'wouter';
import { Stack } from 'styled-system/jsx';
import { PageContent } from '~/lib/layout';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
} from '~/lib/routes';
import SelectorDropdown from './components/SelectorDropdown';
import Divinations from './content/Divinations';
import Journals from './content/Journals';

const ListView = () => {
  return (
    <PageContent>
      <Stack direction="column" gap={4} px={[3.5, 5]}>
        <Stack direction="row" gap={4}>
          <SelectorDropdown size="lg" />
        </Stack>
        <Switch>
          <Route path={DIVINATION_ROUTE} component={Divinations} />
          <Route path={JOURNAL_ROUTE} component={Journals} />
          <Route path={LIST_DIVINATIONS_ROUTE} component={Divinations} />
          <Route path={LIST_JOURNALS_ROUTE} component={Journals} />
        </Switch>
      </Stack>
    </PageContent>
  );
};

export default ListView;
