import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'wouter';
import { Center } from 'styled-system/jsx';
import { Loader } from '@ichingio/ui';
import { BreakpointsProvider } from '@ichingio/viewport';
import { useAuthentication } from '~/lib/auth';
import { LayoutProvider } from '~/lib/layout';
import {
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  LIST_JOURNALS_ROUTE,
  LOGIN_ROUTE,
  SETTINGS_ROUTE,
} from '~/lib/routes';
import { DivinateProvider } from '~/modals/DivinationFlow';
import { DivinationProvider } from '~/modals/DivinationView';
import { JournalProvider } from '~/modals/JournalEditor';
import { SearchProvider } from '~/modals/Search';
import ListView from '~/screens/ListView';

const LoadingOverlay = () => (
  <Center width="100%" height="100%">
    <Loader />
  </Center>
);

const Auth = lazy(() => import('~/screens/Auth'));
const Settings = lazy(() => import('~/screens/Settings'));

const App = () => {
  const { isAuthenticated, isLoading } = useAuthentication();

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (!isAuthenticated) {
    return (
      <Suspense fallback={<LoadingOverlay />}>
        <Redirect to={LOGIN_ROUTE} />
        <Route path={LOGIN_ROUTE} component={Auth} />
      </Suspense>
    );
  }

  return (
    <BreakpointsProvider>
      <LayoutProvider>
        <SearchProvider>
          <JournalProvider>
            <DivinationProvider>
              <DivinateProvider>
                <Suspense>
                  <Switch>
                    <Route path={LIST_DIVINATIONS_ROUTE} component={ListView} />
                    <Route path={LIST_JOURNALS_ROUTE} component={ListView} />
                    <Route path={DIVINATION_ROUTE} component={ListView} />
                    <Route path={JOURNAL_ROUTE} component={ListView} />
                    <Route path={SETTINGS_ROUTE} component={Settings} />
                    <Redirect to={LIST_DIVINATIONS_ROUTE} />
                  </Switch>
                </Suspense>
              </DivinateProvider>
            </DivinationProvider>
          </JournalProvider>
        </SearchProvider>
      </LayoutProvider>
    </BreakpointsProvider>
  );
};

export default App;
