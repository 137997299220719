export enum CharCodes {
  BREAK = 10,
  SPACE = 32,
}

export enum KeyCodes {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ENTER = 'Enter',
  ESC = 'Escape',
}

export const NUMBERS = new Set([
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]);

export const ACCENT_CHARS_TO_CHARS = new Map<string, string>([
  ['á', 'a'],
  ['à', 'a'],
  ['ã', 'a'],
  ['â', 'a'],
  ['ä', 'a'],
  ['Á', 'A'],
  ['À', 'A'],
  ['Ã', 'A'],
  ['Â', 'A'],
  ['Ä', 'A'],
  ['ç', 'c'],
  ['Ç', 'C'],
  ['é', 'e'],
  ['è', 'e'],
  ['ê', 'e'],
  ['ë', 'e'],
  ['É', 'E'],
  ['È', 'E'],
  ['Ê', 'E'],
  ['Ë', 'E'],
  ['í', 'i'],
  ['ì', 'i'],
  ['î', 'i'],
  ['ï', 'i'],
  ['Í', 'I'],
  ['Ì', 'I'],
  ['Î', 'I'],
  ['Ï', 'I'],
  ['ñ', 'n'],
  ['Ñ', 'N'],
  ['ó', 'o'],
  ['ò', 'o'],
  ['õ', 'o'],
  ['ô', 'o'],
  ['ö', 'o'],
  ['Ó', 'O'],
  ['Ò', 'O'],
  ['Õ', 'O'],
  ['Ô', 'O'],
  ['Ö', 'O'],
  ['ú', 'u'],
  ['ù', 'u'],
  ['û', 'u'],
  ['ü', 'u'],
  ['Ú', 'U'],
  ['Ù', 'U'],
  ['Û', 'U'],
  ['Ü', 'U'],
  ['ý', 'y'],
  ['ÿ', 'y'],
  ['Ý', 'Y'],
]);

export const ACCENT_CHARS = new Set<string>(ACCENT_CHARS_TO_CHARS.keys());

export const PUNCTUATION = new Set(['.', ',', '?', '!']);

const probablyNumChars = new Set([...NUMBERS, '#', '.', ':']);

export const isProbablyNumber = (input: string | number): boolean => {
  if (typeof input === 'number') {
    if (Number.isNaN(input)) {
      return false;
    }

    return true;
  }

  for (const char of input) {
    if (!probablyNumChars.has(char)) {
      return false;
    }
  }

  return true;
};

export const toTitleCase = (input: string) => {
  const { transform } = Array.from(input).reduce<{
    transform: string[];
    prev: string | null;
  }>(
    (res, char) => {
      if (res.prev === null || res.prev === ' ') {
        res.transform.push(char.toUpperCase());
      } else {
        res.transform.push(char);
      }

      res.prev = char;

      return res;
    },
    {
      transform: [],
      prev: null,
    },
  );

  return transform.join('');
};

export const findLastBreakingSpace = (
  content: string,
  maxLength = content.length,
) => {
  let index = maxLength - 1;

  while (index) {
    const code = content.charCodeAt(index);

    if (code === CharCodes.BREAK || code === CharCodes.SPACE) {
      return index + 1;
    }

    index--;
  }

  return maxLength;
};

export const removeDoubleBreaks = (text: string) =>
  text.replace(/\n\n/gi, '\n').replace(/\n\n\n/gi, '\n');

export const truncateText = (content: string, maxLength: number) => {
  if (content.length < maxLength) {
    return content;
  }

  let truncated = content
    .slice(0, findLastBreakingSpace(content, maxLength))
    .trim();
  const last = truncated.charAt(truncated.length - 1);

  if (PUNCTUATION.has(last)) {
    truncated = truncated.slice(0, truncated.length - 1);
  }

  if (content.length !== truncated.length) {
    return `${truncated}...`;
  }

  return truncated;
};

export const normalizeString = (q: string) =>
  q
    .toLowerCase()
    .trim()
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '');

export const sanitizeEmailAddress = (email: string) => {
  const [user, domain] = email.toLowerCase().replace(' ', '').split('@');
  const url = new URL(`http://${domain}`);

  return `${user}@${url.host}`;
};

export const normalizeUrl = (initialUrl: string) => {
  const cleaned = initialUrl.replace('javascript:', '').trim();
  const [maybeProto, maybeDomain] = cleaned.split('://');
  const baseUrl = maybeDomain ? cleaned : `http://${maybeProto}`;

  try {
    const url = new URL(baseUrl);
    url.hostname = url.hostname.replace(/\s/g, '');
    url.pathname = decodeURIComponent(url.pathname).replace(/ /g, '%20');

    return url.href;
  } catch (error) {
    console.error(`Invalid URL: ${error}`);
    return '#';
  }
};

export const parseNumber = (input: string | number) => {
  if (typeof input === 'number') {
    return input;
  }

  return parseFloat(input.replace(/[^0-9.]/, ''));
};

export const quantizeNumber = (value: number, quantum: number) => {
  const remainder = value % quantum;
  const sign = Math.sign(value);
  const mod = remainder ? quantum : 0;

  return value - remainder + sign * mod;
};
