import { AppLauncher, type OpenURLResult } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { useCallback } from 'react';
import { sanitizeEmailAddress } from '@ichingio/utils';
import { usePlatform } from '@ichingio/viewport';

export const openAnchorLink = (
  url: string,
  onClick?: (e: MouseEvent) => void,
): HTMLAnchorElement => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';

  if (onClick) {
    link.onclick = onClick;
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return link;
};

export type LinkPayload = {
  url: string;
};

export const useOpenLink = (initialPayload?: LinkPayload) => {
  const { isNative, isStandalone } = usePlatform();

  return useCallback(
    async (payload = initialPayload): Promise<void> => {
      if (!payload || !payload.url) {
        throw new Error('URL is undefined');
      }

      const { url } = payload;

      if (isNative || isStandalone) {
        try {
          return await Browser.open({
            url,
            presentationStyle: 'popover',
          });
        } catch (err) {
          console.error(err);
          openAnchorLink(url);
        }
      }

      openAnchorLink(url);
    },
    [isNative, isStandalone, initialPayload],
  );
};

export const formatMailtoLink = ({
  email,
  subject,
}: {
  email: string;
  subject?: string;
}): string =>
  `mailto:${sanitizeEmailAddress(email)}${subject ? `?subject=${encodeURIComponent(subject)}` : ''}`;

export type EmailPayload = {
  email: string;
  subject?: string;
};

export const useEmailLink = (initialPayload?: EmailPayload) => {
  const { isNative, isStandalone } = usePlatform();

  return useCallback(
    async (payload = initialPayload): Promise<OpenURLResult | void> => {
      if (!payload || !payload.email) {
        throw new Error('Email is undefined');
      }

      const { email, subject } = payload;
      const url = formatMailtoLink({ email, subject });

      if (isNative || isStandalone) {
        try {
          const { value: canOpenUrl } = await AppLauncher.canOpenUrl({ url });

          if (canOpenUrl) {
            return await AppLauncher.openUrl({ url });
          } else {
            throw new Error(`Cannot open ${url}`);
          }
        } catch (err) {
          console.error(err);
          openAnchorLink(url);
        }
      }

      openAnchorLink(url);
    },
    [isNative, isStandalone, initialPayload],
  );
};
