import { animated, config, useTransition } from '@react-spring/web';
import { useEffect, useState, type PropsWithChildren } from 'react';
import { useRefGetter } from '@ichingio/hooks';
import { useLayoutContext } from '~/lib/layout';

const ShowHideDropdown = (props: PropsWithChildren) => {
  const { children } = props;
  const { scrollElement } = useLayoutContext();
  const [isVisible, toggleVisibility] = useState(false);
  const getIsVisible = useRefGetter(isVisible);

  useEffect(() => {
    if (scrollElement) {
      let prevScrollTop = scrollElement.scrollTop;

      const handleScroll: EventListener = () => {
        const current = getIsVisible();
        const threshold =
          Math.max(prevScrollTop - scrollElement.scrollTop, 0) > 128
            ? 1024
            : 64;
        const updates =
          scrollElement.scrollTop > threshold &&
          scrollElement.scrollTop <= prevScrollTop;

        if (current !== updates) {
          toggleVisibility(updates);
        }

        prevScrollTop = scrollElement.scrollTop;
      };

      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollElement, getIsVisible]);

  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  return transitions((style, shouldRender) =>
    shouldRender ? <animated.div style={style}>{children}</animated.div> : null,
  );
};

export default ShowHideDropdown;
