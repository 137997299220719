import { usePaginatedQuery } from 'convex/react';
import { memo } from 'react';
import { Stack } from 'styled-system/jsx';
import { api } from '@ichingio/server/api';
import { type ListDivinationsItem } from '@ichingio/types';
import { useScrollObserver } from '~/lib/layout';
import DivinationGridItem from '../components/DivinationGridItem';
import RowContainer from '../components/RowContainer';
import WelcomeTutorial from '../components/WelcomeTutorial';
import useDivinationRouter from '../hooks/useDivinationRouter';
import useGridRows from '../hooks/useGridRows';
import usePageSize from '../hooks/usePageSize';

const DivinationsList = memo<{ onNavigate: (to: string) => void }>(
  function DivinationsList(props) {
    const { onNavigate } = props;
    const { gridColumnCount, initialPage, nextPage } = usePageSize();
    const { results, loadMore, status } = usePaginatedQuery(
      api.divinations.listDivinations,
      {},
      { initialNumItems: initialPage },
    );
    const rows = useGridRows<ListDivinationsItem>(results, gridColumnCount);

    useScrollObserver(() => loadMore(nextPage));

    return (
      <Stack direction="column" gap={[2.5, 4]}>
        {status !== 'LoadingFirstPage' && (
          <>
            {rows?.length ? (
              <>
                {rows.map((row) => {
                  return (
                    <RowContainer key={row.key} count={gridColumnCount}>
                      {row.items.map((item) => {
                        return (
                          <DivinationGridItem
                            key={item.id}
                            item={item}
                            onNavigate={onNavigate}
                          />
                        );
                      })}
                    </RowContainer>
                  );
                })}
              </>
            ) : (
              <WelcomeTutorial />
            )}
          </>
        )}
      </Stack>
    );
  },
);

const Divinations = () => {
  const onNavigate = useDivinationRouter();

  return (
    <>
      <DivinationsList onNavigate={onNavigate} />
    </>
  );
};

export default Divinations;
