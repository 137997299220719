export const LIST_DIVINATIONS_ROUTE = '/d';

export const DIVINATION_ROUTE = '/d/:divinationId';

export type DivinationParams = {
  divinationId: string;
};

export const getDivinationRoute = (id: string) =>
  DIVINATION_ROUTE.replace(':divinationId', id);

export const LIST_JOURNALS_ROUTE = '/j';

export type JournalParams = {
  journalId: string;
};

export const JOURNAL_ROUTE = `/j/:journalId`;

export const getJournalRoute = (id: string) =>
  JOURNAL_ROUTE.replace(':journalId', id);

export const SETTINGS_ROUTE = '/settings';

export const LOGIN_ROUTE = '/login';

export const ABOUT_PAGE_URL = 'https://iching.io/about';

export const DIVINATION_GUIDE_URL = 'https://iching.io/divination';

export const PRIVACY_POLICY_URL = 'https://iching.io/privacy';

export const SUPPORT_EMAIL_ADDRESS = 'hello@iching.io';
