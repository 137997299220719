import { Root, Main, PageContent } from './Layout';
import LayoutProvider from './LayoutProvider';
import FloatingHeader from './components/FloatingHeader';
import { useLayoutContext } from './hooks/useLayoutContext';
import useScrollObserver from './hooks/useScrollObserver';

const Layout = Object.assign(Root, {
  Main,
  PageContent,
  Root,
});

export {
  FloatingHeader,
  Layout,
  LayoutProvider,
  Main,
  PageContent,
  Root,
  useLayoutContext,
  useScrollObserver,
};
