import { useMutation, useQuery } from 'convex/react';
import { useCallback } from 'react';
import { useLocation } from 'wouter';
import { FileTextIcon, LighteningIcon, PlusIcon } from '@ichingio/icons';
import { nanoid } from '@ichingio/nanoid';
import { api } from '@ichingio/server/api';
import { Dropdown } from '@ichingio/ui';
import { getJournalRoute } from '~/lib/routes';
import { useDivinationFlow } from '~/modals/DivinationFlow';

const ActionMenu = () => {
  const [_, navigate] = useLocation();
  const { startNewDivination } = useDivinationFlow();
  const createWithDivination = useMutation(api.journals.createWithDivination);
  const achievements = useQuery(api.users.loadAchievements);
  const completeWelcomeAchievement = useMutation(
    api.users.completeWelcomeAchievement,
  );

  const handleCreateJournal = useCallback(async () => {
    try {
      const createdId = await createWithDivination({ id: nanoid() });

      if (createdId) {
        if (!achievements || !achievements.hasCompletedWelcome) {
          await completeWelcomeAchievement();
        }

        navigate(getJournalRoute(createdId));
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    achievements,
    completeWelcomeAchievement,
    createWithDivination,
    navigate,
  ]);

  return (
    <Dropdown
      trigger={() => (
        <Dropdown.IconTrigger>
          <PlusIcon size="md" />
        </Dropdown.IconTrigger>
      )}
    >
      <Dropdown.Content align="end" width="10.75rem">
        <Dropdown.Item
          icon={<LighteningIcon size="sm" />}
          onSelect={() => startNewDivination()}
        >
          Divination
        </Dropdown.Item>
        <Dropdown.Item
          icon={<FileTextIcon size="sm" />}
          onSelect={() => handleCreateJournal()}
        >
          Journal
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ActionMenu;
