import { useCallback } from 'react';
import { useLocation, useRoute } from 'wouter';
import { wait } from '@ichingio/nn';
import { NavDropdown } from '@ichingio/ui';
import { useLayoutContext } from '~/lib/layout';
import {
  LIST_JOURNALS_ROUTE,
  LIST_DIVINATIONS_ROUTE,
  DIVINATION_ROUTE,
  JOURNAL_ROUTE,
} from '~/lib/routes';

const SelectorDropdown = (props: { size?: 'md' | 'lg' }) => {
  const { size = 'md' } = props;
  const [_, navigate] = useLocation();
  const { scrollElement } = useLayoutContext();
  const [isDivinations] = useRoute(LIST_DIVINATIONS_ROUTE);
  const [isJournals] = useRoute(LIST_JOURNALS_ROUTE);
  const [isDivination] = useRoute(DIVINATION_ROUTE);
  const [isJournal] = useRoute(JOURNAL_ROUTE);
  let content: string | null = null;

  if (isDivinations || isDivination) {
    content = 'Divinations';
  }
  if (isJournals || isJournal) {
    content = 'Journals';
  }

  const scrollUpAndWait = useCallback(async () => {
    if (scrollElement && scrollElement.scrollTop > 50) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });

      return new Promise<void>((resolve) => {
        const checkScroll = async () => {
          if (scrollElement.scrollTop < 16) {
            await wait(168);
            resolve();
          } else {
            await wait(42);
            checkScroll();
          }
        };

        checkScroll();
      });
    }
  }, [scrollElement]);

  return (
    <NavDropdown.Root
      trigger={
        <NavDropdown.Trigger tabIndex={1} ml="2px" size={size}>
          {content}
        </NavDropdown.Trigger>
      }
    >
      <NavDropdown.Content alignOffset={-4} minWidth="10rem">
        <NavDropdown.Item
          isSelected={isDivinations}
          onSelect={async () => {
            await scrollUpAndWait();

            if (!isDivinations) {
              navigate(LIST_DIVINATIONS_ROUTE);
            }
          }}
        >
          Divinations
        </NavDropdown.Item>
        <NavDropdown.Item
          isSelected={isJournals}
          onSelect={async () => {
            await scrollUpAndWait();

            if (!isJournals) {
              navigate(LIST_JOURNALS_ROUTE);
            }
          }}
        >
          Journals
        </NavDropdown.Item>
      </NavDropdown.Content>
    </NavDropdown.Root>
  );
};

export default SelectorDropdown;
