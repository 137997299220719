import { type PropsWithChildren } from 'react';
import { useLocation } from 'wouter';
import { Flex, Spacer } from 'styled-system/jsx';
import { SearchIcon, SettingsIcon } from '@ichingio/icons';
import { IconButton } from '@ichingio/ui';
import { Layout, FloatingHeader } from '~/lib/layout';
import { SETTINGS_ROUTE } from '~/lib/routes';
import { useNavigateTrigger } from '~/modals/Search';
import ActionMenu from './components/ActionMenu';
import SelectorDropdown from './components/SelectorDropdown';
import ShowHideDropdown from './components/ShowHideDropdown';
import ThunderDot from './components/ThunderDot';

const SearchTrigger = () => {
  const { openSearchModal } = useNavigateTrigger();

  return (
    <IconButton onPress={() => openSearchModal()}>
      <SearchIcon size="md" />
    </IconButton>
  );
};

const Settings = () => {
  const [_, navigate] = useLocation();

  return (
    <IconButton onPress={() => navigate(SETTINGS_ROUTE)}>
      <SettingsIcon size="md" />
    </IconButton>
  );
};

const Header = () => {
  return (
    <FloatingHeader>
      <FloatingHeader.Content pl={[3, 5]} pr={[2, 3, 3, 4]}>
        <ShowHideDropdown>
          <SelectorDropdown />
        </ShowHideDropdown>
        <Spacer />
        <ActionMenu />
        <SearchTrigger />
        <Settings />
      </FloatingHeader.Content>
    </FloatingHeader>
  );
};

const Footer = () => {
  return (
    <Flex position="fixed" bottom="1.75rem" right={['0.5rem', '1.25rem']}>
      <ThunderDot />
    </Flex>
  );
};

const ListViewLayout = (props: PropsWithChildren) => {
  const { children } = props;
  return (
    <Layout.Root>
      <Header />
      <Layout.Main>{children}</Layout.Main>
      <Footer />
    </Layout.Root>
  );
};

export default ListViewLayout;
